import React from "react";
import styled from "styled-components";
import Logo from "../../assets/images/logo/logo-detoure.png";
import Link from "gatsby-link";
import Obfuscate from "react-obfuscate";

const Image = styled.img`
  width: 200px;
  &:hover {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
`;

const Footer = () => {
  return (
    <footer id="footer" className="footer footer-2 footer-4">
      <div className="footer-container">
        {/* <div className="footer-widget">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                <div className="widget-container">
                  <h3>Restons en contact !</h3>
                  <ul className="list-inline">
                    <li>
                      <a href="#">linkedin</a>
                    </li>
                    <li>
                      <a href="#">twitter</a>
                    </li>
                    <li>
                      <a href="#">facebook</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-logo">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="text-center">
                  <Link to="/">
                    <Image src={Logo} alt="Logo Azerty Studio" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-info-widget">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="contact-info">
                  <a
                    href="https://goo.gl/maps/WWCadfSUKYR2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-map-marker" />
                    28 Boulevard Benoni Goullin, Nantes
                  </a>

                  <p>
                    <i className="fa fa-envelope" />
                    <Obfuscate
                      style={{ display: "inline-block" }}
                      email="vincent@azerty-studio.fr"
                    />
                  </p>

                  <p>
                    <i className="fa fa-phone" />
                    <Obfuscate
                      style={{ display: "inline-block" }}
                      tel="0272207121"
                    />
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="bio-info text-center">
                  <p>
                    Azerty est un studio nantais de création de sites internet
                    modernes, ergonomiques et optimisés pour générer du trafic
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="copyright-info text-right">
                  <p>&copy;2019 Azerty Studio. All Rights Reserved</p>
                  <Link to="/mentions-legales">Mentions légales - CGU</Link>
                  <p>
                    Développé avec <i className="fas fa-heart fa-pulse" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
