import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Navbar from "./navbar";
import "bootstrap/dist/css/bootstrap-grid.min.css";

import "../assets/less/style.less";
import Footer from "./footer";

const Wrapper = styled.div`
  &.etude-de-cas-layout h1 {
    text-transform: initial !important;
  }
`;

const Layout = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Wrapper id="wrapper" className={`wrapper clearfix ${className}`}>
          <Navbar />

          <main>{children}</main>
          <Footer />
        </Wrapper>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
