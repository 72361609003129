import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Logo from "../logo";

const WrapperBurger = styled.button`
  cursor: pointer;
  z-index: 4;
  width: 40px;
  display: none;
  margin-right: 15px;
  background: transparent;
  border: none;
  @media (max-width: 991px) {
    display: block;
  }
`;

const BurgerBar = styled.span`
  display: block;
  width: 100%;
  height: 4px;
  background-color: #222222;
  border-radius: 4px;
  margin: 8px 0;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid white;
  &:nth-child(1) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(-405deg) translate(-10px,7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
  &:nth-child(2) {
    background-color: ${props =>
      props.isBurgerActive ? "transparent" : "#222222"};
  }
  &:nth-child(3) {
    transform: ${props =>
      props.isBurgerActive
        ? "rotate(405deg) translate(-10px,-7px)"
        : "#rotate(0deg) translate(0,0)"};
  }
`;

const Menu = styled.div`
  @media (max-width: 991px) {
    /* width: 100%; */
    height: ${props => (props.isBurgerActive ? "250px" : "0")};
    opacity: ${props => (props.isBurgerActive ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }
`;

class Navbar extends React.Component {
  state = {
    isBurgerActive: false
  };

  toggleMenu = () => {
    this.setState({ isBurgerActive: !this.state.isBurgerActive });
  };

  render() {
    const { isBurgerActive } = this.state;

    return (
      <header id="navbar-spy" className="header header-1">
        <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid container-p0">
            <Logo />
            {/* <button
              className="navbar-toggler custom-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarContent"
              aria-controls="navbarContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button> */}

            <WrapperBurger onClick={this.toggleMenu}>
              <BurgerBar isBurgerActive={isBurgerActive} />
              <BurgerBar isBurgerActive={isBurgerActive} />
              <BurgerBar isBurgerActive={isBurgerActive} />
            </WrapperBurger>

            <Menu id="navbarContent" isBurgerActive={isBurgerActive}>
              <ul className="navbar-nav ml-auto">
                <li>
                  <Link to="/" className="menu-item" activeClassName="active">
                    accueil
                  </Link>
                </li>

                <li>
                  <Link
                    to="/offres"
                    className="menu-item"
                    activeClassName="active"
                  >
                    nos offres
                  </Link>
                </li>
                <li>
                  <Link
                    to="/realisations"
                    className="menu-item"
                    activeClassName="active"
                  >
                    nos réalisations
                  </Link>
                </li>

                <li>
                  <Link
                    to="/a-propos"
                    className="menu-item"
                    activeClassName="active"
                  >
                    à propos
                  </Link>
                </li>

                <li>
                  <Link
                    to="/contact"
                    className="menu-item"
                    activeClassName="active"
                  >
                    contact
                  </Link>
                </li>

                <li>
                  <Link
                    to="/blog"
                    className="menu-item"
                    activeClassName="active"
                  >
                    blog
                  </Link>
                </li>
              </ul>
            </Menu>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
