import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";

const LogoStyled = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: ${props => (props.color ? props.color : "#333333")};
  position: relative;
  padding: 20px;
  line-height: 0;
  display: inline-block;
  margin-bottom: 0;
  &::before {
    position: absolute;
    content: "";
    pointer-events: none;
    top: 3px;
    right: 0;
    bottom: 3px;
    left: 0;
    border-top: 2px solid ${props => (props.color ? props.color : "#333333")};
    border-bottom: 2px solid ${props => (props.color ? props.color : "#333333")};
  }
  &::after {
    position: absolute;
    content: "";
    pointer-events: none;
    top: 0;
    right: 3px;
    bottom: 0;
    left: 3px;
    border-right: 2px solid ${props => (props.color ? props.color : "#333333")};
    border-left: 2px solid ${props => (props.color ? props.color : "#333333")};
  }
  &:hover {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
`;

const Logo = ({ color }) => {
  return (
    <Link className="navbar-brand" to="/">
      <LogoStyled color={color}>Azerty Studio</LogoStyled>
    </Link>
  );
};

export default Logo;
