import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Background = styled.div`
  position: relative;
`;

const Baseline = styled.p`
  color: ${props => (props.color ? props.color : "#4f5054")};
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 2rem;
  @media (max-width: 991px) {
    line-height: 22px;
  }
`;

const Banner = ({ title, baseline, imageBanner, color, tag }) => {
  return (
    <section id="page-title" className="page-title">
      <Background className="container-fluid ">
        <Img fluid={imageBanner} alt="Azerty Studio Banniere" />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-2">
              <div className="title-content">
                <div className="title-heading">
                  <h1 className={tag}>{title}</h1>
                </div>
                <Baseline color={color}>{baseline}</Baseline>
              </div>
            </div>
          </div>
        </div>
      </Background>
    </section>
  );
};

export default Banner;
